
import React, { useEffect, useState, useContext } from 'react';
import {Modal, Button, Toast, Row, Col, Container, CloseButton, Form} from "react-bootstrap";
import eventBus from "../controls/eventBus";

function BlendOptions(props) {

  const [type, setType] = useState("distance");
  const [buffer, setBuffer] = useState("10");
  const [weight, setWeight] = useState("weighted");
  const [random, setRandom] = useState("0");
  const [iteration, setIteration] = useState("1");

  const handleClose = () => {
    //remove from clipboard
    props.onClose();
  };

  const handleApply = () => {
    eventBus.dispatch("applyBlendOptions", { type: type, buffer:parseInt(buffer), weight:weight, random:parseInt(random), iteration:parseInt(iteration) });
  }

  const handleClear = () => {
    eventBus.dispatch("clearLocationSelection", {});
  }

  const fieldUpdate = (e) => {
    if (e.currentTarget.name == "type"){
      setType(e.currentTarget.value);
    } else if (e.currentTarget.name == "buffer"){
      setBuffer(e.currentTarget.value);
    } else if (e.currentTarget.name == "weight"){
      setWeight(e.currentTarget.value);
    } else if (e.currentTarget.name == "random"){
      setRandom(e.currentTarget.value);
    } else if (e.currentTarget.name == "iteration"){
      setIteration(e.currentTarget.value);
    }
  }

return (
  <div style={{position:"absolute", top:10, right:20, zIndex:99}}>
    <Toast className="clipboard" show={props.show} onClose={handleClose}>
      <Toast.Body>
        <Container fluid>
        <Row>
          <Col sm={11}><b>Location Blend Options</b></Col>
          <Col xs={1}><CloseButton onClick={handleClose}/></Col>
        </Row>
        <Row><Col>&nbsp;</Col></Row>
        <Row>
          <Col sm={4}>Type</Col>
          <Col xs={8}>
              <Form.Select name="type" value={type} onChange={fieldUpdate.bind(this)}>
              <option value={"distance"}>Distance</option>
              <option value={"time"}>Time</option>
              </Form.Select>
            </Col>
        </Row>
        <Row>
          <Col sm={4}>Buffer</Col>
          <Col xs={6}><Form.Control type="input" name="buffer" value={buffer} onChange={fieldUpdate.bind(this)}/></Col>
          {type == "distance" &&
            <Col sm={2}>m</Col>
          }
          {type == "time" &&
            <Col sm={2}>s</Col>
          }
        </Row>
        <Row>
          <Col sm={4}>Weight</Col>
          <Col xs={8}>
              <Form.Select name="weight" value={weight} onChange={fieldUpdate.bind(this)}>
              <option value={"weighted"}>Accuracy</option>
              <option value={"manual"}>Manual Only</option>
              <option value={"selected"}>Selected Only</option>
              <option value={"unweighted"}>None</option>
              </Form.Select>
            </Col>
        </Row>
        <Row>
          <Col sm={4}>Random</Col>
          <Col xs={8}><Form.Control type="input" name="random" value={random} onChange={fieldUpdate.bind(this)}/></Col>
        </Row>
        <Row>
          <Col sm={4}>Iteration</Col>
          <Col xs={8}><Form.Control type="input" name="iteration" value={iteration} onChange={fieldUpdate.bind(this)}/></Col>
        </Row>
        <Row><Col>&nbsp;</Col></Row>
        <Row>
        <Col sm={4}><Button variant="outline-primary" size="sm" onClick={handleClear}>Clear</Button></Col>
        <Col sm={5}></Col>
          <Col sm={2}><Button variant="outline-primary" size="sm" onClick={handleApply}>Apply</Button></Col>
          </Row>
        </Container>
      </Toast.Body>
    </Toast>
  </div>
)
  
}

export default BlendOptions;
