
import React, { useEffect, useState } from "react";
import { addFeatures, view, zoomToFeature, clearHighlight, zoomToLayerbyId} from '../../controllers/mapController';
import * as math from 'mathjs';
import eventBus from "../controls/eventBus";

export default function GeoFeatureLayer(props) {

  const [type, setType] = useState("distance");
  const [buffer, setBuffer] = useState(10);
  const [weight, setWeight] = useState("weighted");
  const [random, setRandom] = useState(0);
  const [iteration, setIteration] = useState(1);
  const [update, setUpdate] = useState(false);
  
  var callback = (data) => {
    setType(data.detail.type);
    setBuffer(data.detail.buffer);
    setWeight(data.detail.weight);
    setRandom(data.detail.random);
    setIteration(data.detail.iteration);
    setUpdate(!update);
  }

  eventBus.on("applyBlendOptions", callback);

  function addGeoFeatures(geofeatures, type){
    var features = [];
    var fields = [];
    geofeatures.forEach(function(feature,i){
      var lat = feature.Latitude, lon = feature.Longitude
      if (type.indexOf("blend") >= 0){
        lat = feature.ARLatitude
        lon = feature.ARLongitude
      }
      if (lat && lon){
        feature["ObjectID"] = i+1
        features.push({
          geometry: {
            type: "point",
            latitude: lat,
            longitude: lon
          },
          attributes: feature
        });
      }
    });
    
    var color = "blue";
    var outlineColor = [255, 255, 255];
    if (type == "vps"){
      color = "red"
    }
    else if (type == "origin"){
      color = "white"
      outlineColor = "green"
    }
    else if (type == "blend"){
      color = "green"
    }
    else if (type.indexOf("blend">=0)){
      color = "gray"
    }
    else if (type == "final"){
      color = "yellow"
    }

    var renderer = {
      type: "simple",  // autocasts as new SimpleRenderer()
      symbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      style: "circle",
      color: color,
      size: "12px",  // pixels
      outline: {
      // autocasts as new SimpleLineSymbol()
      color: outlineColor,
      width: 1
      }
      }
    };

    var fields = [
      {name: "ObjectID",
        alias: "ObjectID",
        type: "oid"}
    ]
    
    Object.keys(geofeatures[0]).forEach(key => {
     fields.push({
      name:key,
      alias:key,
      type:"string"
     })
    });
    
    var fieldInfos = fields.map(function(f){
      return {fieldName: f.name, label: f.alias}
    });
    const pTemplate = {
      // autocasts as new PopupTemplate()
      title: "{Name}",
      content: [
        {
          // It is also possible to set the fieldInfos outside of the content
          // directly in the popupTemplate. If no fieldInfos is specifically set
          // in the content, it defaults to whatever may be set within the popupTemplate.
          type: "fields",
          fieldInfos: fieldInfos
        }
      ]
    };
  
    addFeatures(features, renderer, fields, pTemplate, "f-geofeature" + type, "Feature location " + type, false, 2);
  }

  function degressOfLongitude(meters, originLat){
    let latitudeRadians = (originLat * Math.PI) / 180
    let metersPerLongitudeDegree = 40075 * 1000 * Math.cos(latitudeRadians) / 360
    let degrees = meters / metersPerLongitudeDegree

    return degrees
  }
  
  function degreesOfLatitude(meters){
    let metersPerLatitudeDegree = 111.32 * 1000
    let degrees = meters / metersPerLatitudeDegree
    return degrees
  }

  function convertARCoordsToLatLon(feature, origin){
      var x = feature["Feature_AR_X-Coordinate"] * 1.0 
      var y = feature["Feature_AR_Z-Coordinate"] * -1.0 

      var originX = origin["Feature_AR_X-Coordinate"] * 1.0
      var originY = origin["Feature_AR_Z-Coordinate"] * -1.0

      //Find the difference in meters between origin and feature
      var diffX = x - originX;
      var diffY = y - originY;

      var diffLat = degreesOfLatitude(diffY)
      var diffLon = degressOfLongitude(diffX, origin["Latitude"])

      feature["ARLatitude"] = origin["Latitude"] * 1.0 + diffLat;
      feature["ARLongitude"] = origin["Longitude"] * 1.0 + diffLon;
  }

  function calculateDistance(point1, point2) {
    const dx = point2.x - point1.x;
    const dy = point2.y - point1.y;
    return Math.sqrt(dx * dx + dy * dy);
  }

  function calculateTimeDistance(time1, time2) {
    const timestamp1 = Date.parse(time1); 
    const timestamp2 = Date.parse(time2); 
    const differenceInSeconds = Math.abs(timestamp2 - timestamp1)/1000;
    return differenceInSeconds
  }

  function getRandomItems(array, numItems) {
    // Shuffle the array using Fisher-Yates algorithm
    const shuffled = array.slice(); // Copy the array
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap
    }

    // Return the first `numItems` elements
    return shuffled.slice(0, numItems);
}

  function calculateBlendedPosition(targetPosition){
   
    //Get userPositions around targetPosition
    var bufferLocations = []
    props.userLocations.forEach(function(u){
      if (weight == "weighted" && u.type != "manual"){
        if (type == "distance"){
          let distance = calculateDistance(targetPosition,{x:parseFloat(u.cameraX),y:-parseFloat(u.cameraZ)})
          if (distance < buffer){
            bufferLocations.push(u)
          }
        } else if (type == "time"){
          let distance = calculateTimeDistance(targetPosition.timestamp,u.timestamp)
          if (distance < buffer){
            bufferLocations.push(u)
          }
        }
      } else if ( (weight == "manual" && u.type == "manual") || 
      (weight == "selected" && u.selected == "true")){
        bufferLocations.push(u)
      }
     
    })

    if (bufferLocations.length > 0){
      var result = null;
      var iterations = [];
      for(var i=0; i<iteration; i++){
         //Check the random option to see if we need to filter it down
         var randomLocations = bufferLocations
        if (random > 0){
          randomLocations = getRandomItems(bufferLocations,random)
        }
        
        let localPositions = randomLocations.map(function(l){
          return {x:parseFloat(l.cameraX),y:-parseFloat(l.cameraZ)}
        })
        let geoPoints = randomLocations.map(function(l){
          return {latitude:parseFloat(l.userLatitude),longitude:parseFloat(l.userLongitude)}
        })
    
        let errors = randomLocations.map(function(l){
          if (weight == "weighted" || weight == "manual"){
            return parseFloat(l.userHorizontalAccuracy)
          } else {
            return 1
          }
          
        })

        let iterationResult = weightedLeastSquaresTransform(localPositions, geoPoints, errors, targetPosition)
    
        if (iterationResult){
          iterations.push(iterationResult)
          console.log("Transformed Latitude: " + iterationResult.latitude)
          console.log("Transformed Longitude: " + iterationResult.longitude)
          console.log("Residual Error: " + iterationResult.residual)
          if (result && result.residual){
            if (iterationResult.residual < result.residual){
              result = iterationResult
            } 
          } else {
            result = iterationResult
          }
        }
      }

      if (iterations && iterations.length > 1){
        result.iterations = iterations
      }
      
      return result
    } else {
      return null
    }
    
  }


  // Helper: Multiply two matrices
function multiplyMatrices(A, B) {
  return A.map(row => B[0].map((_, colIndex) =>
      row.reduce((sum, value, rowIndex) => sum + value * B[rowIndex][colIndex], 0)
  ));
}

// Helper: Transpose a matrix
function transposeMatrix(A) {
  return A[0].map((_, colIndex) => A.map(row => row[colIndex]));
}

// Helper: Create a diagonal matrix from an array
function createDiagonalMatrix(weights) {
  return weights.map((weight, index) =>
      Array(weights.length).fill(0).map((_, i) => (i === index ? weight : 0))
  );
}

// Helper: Invert a 4x4 matrix (assuming it's invertible)
function invertMatrix(M) {
  // Simple implementation using numeric.js library or manual determinant/inverse calculation
  // For simplicity here, use a linear algebra library like `math.js`.
  return math.inv(M);
}

function weightedLeastSquaresTransform(localPoints, geoPoints, errors, targetPoint) {
  
    if (
      localPoints.length < 3 ||
      localPoints.length !== geoPoints.length ||
      geoPoints.length !== errors.length
  ) {
      console.error("At least 3 points with matching local, geographic coordinates, and error estimates are required.");
      return null;
  }

  
// Known points (transform positions and corresponding lat/long)
  var transformPositions = [];
  var latitudes = [];
  var longitudes = [];
  var weights = [];
 

  for (let i = 0; i < localPoints.length; i++) {
      const local = localPoints[i];
      const geo = geoPoints[i];
      const safeError = errors[i] > 0 ? errors[i] : 1e-6;

      transformPositions.push([local.x, local.y, 1]);
      latitudes.push(geo.latitude);
      longitudes.push(geo.longitude);

      weights.push(1.0 / (safeError * safeError));
  }

    // Step 1: Prepare matrices
    const W = createDiagonalMatrix(weights); // Diagonal weight matrix
    const A = transformPositions;
    const bLat = latitudes.map(lat => [lat]);
    const bLong = longitudes.map(long => [long]);

    // Step 2: Calculate coefficients
    const AT = transposeMatrix(A);
    const W_A = multiplyMatrices(W, A);
    const W_bLat = multiplyMatrices(W, bLat);
    const W_bLong = multiplyMatrices(W, bLong);

    const ATA = multiplyMatrices(AT, W_A);
    const ATAInv = invertMatrix(ATA);

    const ATALat = multiplyMatrices(ATAInv, multiplyMatrices(AT, W_bLat));
    const ATALong = multiplyMatrices(ATAInv, multiplyMatrices(AT, W_bLong));

    // Coefficients for lat and long
    const coefficientsLat = ATALat.flat();
    const coefficientsLong = ATALong.flat();

    // Step 3: Use coefficients to calculate lat/long for a target position
    const latitude = coefficientsLat[0] * targetPoint.x
                + coefficientsLat[1] * targetPoint.y
                + coefficientsLat[2];

    const longitude = coefficientsLong[0] * targetPoint.x
                  + coefficientsLong[1] * targetPoint.y
                  + coefficientsLong[2];

    // Compute residuals
    let residual = 0.0;
    for (let i = 0; i < localPoints.length; i++) {
        const local = localPoints[i];
        const geo = geoPoints[i];
        const error = errors[i];

        const predictedLat = coefficientsLat[0] * local.x + coefficientsLat[1] * local.y + coefficientsLat[2];
        const predictedLon = coefficientsLong[0]* local.x + coefficientsLong[1] * local.y + coefficientsLong[2];

        const latResidual = (geo.latitude - predictedLat) / error;
        const lonResidual = (geo.longitude - predictedLon) / error;

        residual += latResidual * latResidual + lonResidual * lonResidual;
    }

    console.log(`Latitude: ${latitude}, Longitude: ${longitude}, Residual: ${residual}`);

    return {
        latitude,
        longitude,
        residual,
    };
   
  
}
  
  //Add features to map
  useEffect(_ => {
    
      if (props.geoFeatures != null && props.geoFeatures.length > 0){
        if (props.geoFeatures[0].Type){
          //split into gps and vps layers
          var gpsGeoFeatures = props.geoFeatures.filter(function(row){
            return row.Type == "gps"
          })
          if (gpsGeoFeatures.length > 0){
            addGeoFeatures(gpsGeoFeatures, "gps");
            //Add features from blended
            var jobFeatures = gpsGeoFeatures
            var iterations = []
            jobFeatures.forEach(function(f){
              //Point Feature
              var x = f["Feature_AR_X-Coordinate"] * 1.0 
              var y = f["Feature_AR_Z-Coordinate"] * -1.0 
  
              let result = calculateBlendedPosition({x:x,y:y, timestamp:f["Timestamp"]});
              if (result != null){
                f["ARLatitude"] = result.latitude;
                f["ARLongitude"] = result.longitude;
                f["Residual"] = result.residual;
                iterations.push(result.iterations)
              } else {
                f["ARLatitude"] = null;
                f["ARLongitude"] = null;
                f["Residual"] = null;
              }
            })
            addGeoFeatures(jobFeatures, "blend");

            //Add iterations layers
            if (iterations.length > 1){
              for (var i=0;i<iteration;i++){
                var jfs = props.jobFeatures
                jfs.forEach(function(f,j){
                  if (f.properties.ar_coords){
                    if (iterations[j] && iterations[j].length > 0){
                      let featureIteration = iterations[j][i]
                      f["ARLatitude"] = featureIteration.latitude;
                      f["ARLongitude"] = featureIteration.longitude;
                      f["Residual"] = featureIteration.residual;
                      console.log("Iteration: " + i.toString() + "|" + j.toString() + ": " + f["ARLatitude"] + "," + f["ARLongitude"] + "," + f["Residual"])
                    }
                  }
                });
                addGeoFeatures(jfs, "blend" + i.toString());
              }
            }
          
          }
          
          var originFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("origin") >= 0
          })
          if (originFeatures.length > 0){
            addGeoFeatures(originFeatures, "origin");
          }

          var vpsGeoFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("vps") >= 0
          })
          if (vpsGeoFeatures.length > 0){
            addGeoFeatures(vpsGeoFeatures, "vps");

            //Add features from AR Coordinates
            //First we need to inject AR derrived Lat Lon
            /*
            var hasOrigin = false
            vpsGeoFeatures.forEach(function(f){
              //Find the matching origin
              var origin = originFeatures.filter(function(o){
                return f.UUID == o.UUID;
              })
              if (origin.length >0){
                convertARCoordsToLatLon(f, origin[0])
                hasOrigin = true
              }
            })
            if (hasOrigin){
              addGeoFeatures(vpsGeoFeatures, "blend");
            }
            */
          }
          
          var finalFeatures = props.geoFeatures.filter(function(row){
            return row.Type.indexOf("final") >= 0
          })
          if (finalFeatures.length > 0){
            addGeoFeatures(finalFeatures, "final");
          }
        }
        else{
          addGeoFeatures(props.geoFeatures, "gps");
        }
        
      }

  }, [props.geoFeatures, type, buffer, weight, random, iteration, update]);
  return (
    <div />
  );
}