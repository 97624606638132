
import React, { useEffect, useRef } from "react";
import { addFeatures, view, zoomToFeature, clearHighlight, zoomToLayerbyId, addCircleGraphics, attachMapEvent} from '../../controllers/mapController';
import eventBus from "../controls/eventBus";

export default function UserLocationLayer(props) {
  
  var callback = () => {
    view.graphics.removeAll();
    props.userLocations.forEach(function(f){
        delete f.selected
    });
  }

  eventBus.on("clearLocationSelection", callback);

  function addUserLocations(userLocations, type){
    var features = [];
    var fields = [];
    userLocations.forEach(function(feature,i){
      if (feature.userLatitude && feature.userLongitude){
        feature["ObjectID"] = i+1
        features.push({
          geometry: {
            type: "point",
            latitude: feature.userLatitude,
            longitude: feature.userLongitude
          },
          attributes: feature
        });
      }
    });
    
    var color = "orange";
    if (type == "vps"){
      color = "red"
    } else if (type == "manual"){
      color = "purple"
    }

    var renderer = {
      type: "simple",  // autocasts as new SimpleRenderer()
      symbol: {
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      style: "triangle",
      color: color,
      size: "10px",  // pixels
      outline: {
      // autocasts as new SimpleLineSymbol()
      color: [255, 255, 255],
      width: 1
      }
      }
    };

    var fields = [
      {name: "ObjectID",
        alias: "ObjectID",
        type: "oid"}
    ]
    Object.keys(userLocations[0]).forEach(key => {
     fields.push({
      name:key,
      alias:key,
      type:"string"
     })
    });
    
    var fieldInfos = fields.map(function(f){
      return {fieldName: f.name, label: f.alias}
    });
    const pTemplate = {
      // autocasts as new PopupTemplate()
      title: "User Location",
      content: [
        {
          // It is also possible to set the fieldInfos outside of the content
          // directly in the popupTemplate. If no fieldInfos is specifically set
          // in the content, it defaults to whatever may be set within the popupTemplate.
          type: "fields",
          fieldInfos: fieldInfos
        }
      ]
    };

    attachMapEvent("click", function(event){
      view.hitTest(event)
        .then(function(response){
          if (response.results.length > 0) {
            
            var g = response.results.filter(function (result) {
              // check if the graphic belongs to the layer of interest
              return result.graphic.layer.id.indexOf("f-userLocation") >= 0 && result.graphic.layer.id.indexOf("f-userLocationAc") < 0;
            })[0].graphic;
          
            props.userLocations.forEach(function(f){
              if (f["ObjectID"] == g.attributes["ObjectID"]){
                  f.selected = "true"
                  g.symbol = {
                    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
                    style: "triangle",
                    color: "cyan",
                    size: "10px",  // pixels
                    outline: {
                    // autocasts as new SimpleLineSymbol()
                    color: [255, 255, 255],
                    width: 1
                    }
                  }
                  
                  view.graphics.add(g);
              }
            });
            
            
            
          } 
        });
    });
  
    addFeatures(features, renderer, fields, pTemplate, "f-userLocation" + type, "User Location " + type, false, 2);

    addCircleGraphics(features, "f-userLocationAc" + type, "User Location " + type + " ac", false, 3, "userHorizontalAccuracy", color)
  }

  
  //Add features to map
  useEffect(_ => {
   
      if (props.userLocations != null && props.userLocations.length > 0){
        if (props.userLocations[0].type){
          var gpsUserLocations = props.userLocations.filter(function(row){
            return row.type == "gps"
          })
          if (gpsUserLocations.length > 0){
            addUserLocations(gpsUserLocations, "gps");
          }
        
          var vpsUserLocations = props.userLocations.filter(function(row){
            return row.type.indexOf("vps") >= 0
          })
          if (vpsUserLocations.length > 0){
            addUserLocations(vpsUserLocations, "vps");
          }

          var manualUserLocations = props.userLocations.filter(function(row){
            return row.type.indexOf("manual") >= 0
          })
          if (manualUserLocations.length > 0){
            addUserLocations(manualUserLocations, "manual");
          }
          
        }
        else{
          addUserLocations(props.userLocations, "gps");
        }
        
      }

  }, [props.userLocations]);
  return (
    <div />
  );
}