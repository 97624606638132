import React, { useEffect, useState, useRef } from "react";
import { getHeightLabels } from '../../controllers/jobController';
import eventBus from "../controls/eventBus";
import { ReactSVGPanZoom, INITIAL_VALUE, TOOL_NONE, TOOL_AUTO, ToolbarPosition} from 'react-svg-pan-zoom';
import { Toast, ToastContainer, Form, Row, Col, Button} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,  faDrawPolygon, faSquareFull, faArrowPointer, faTrash, faPlus} from '@fortawesome/free-solid-svg-icons'
import {v4 as uuidv4} from 'uuid';

export default function ImageOverlay(props) {
    const [heightLabels, setHeightLabels] = useState(null);
    const [heightLabel, setHeightLabel] = useState(null);
    const [toolbar, setToolbar] = useState(null)
    const [tool, setTool] = useState(TOOL_AUTO)
    const [value, setValue] = useState(INITIAL_VALUE)
    const [showPopup, setShowPopup] = useState(false)
    const [popupTitle, setPopupTitle] = useState("")
    const [popupContent, setPopupContent] = useState("")
    const [selectedElement, setSelectedElement] = useState(null)
    const [isDragging, setIsDragging] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null);
    const [ownerAttr, setOwnerAttr] = useState(null);
    const [heightAttr, setHeightAttr] = useState(null);
    const [fieldValue, setFieldValue] = useState(null);
    const [showAttributeEdit, setShowAttributeEdit] = useState(false)
    const [unitOfMeasurement, setUnitOfMeasurement] = useState("feet")
    const svgRef = useRef(null);
    const Viewer = useRef(null);

    useEffect(_ => {
        //if (Viewer.current != null){
        //    Viewer.current.fitToViewer();
        //}
        setToolbar({position:"none"})
        setTool(TOOL_AUTO);

        if (props.canEdit == true){
            //setTool(TOOL_AUTO);
            
        } else {
            setShowPopup(false);
        }
        if (props.image != null){
            getHeightLabels(props.image.sidecar_presigned_url).then(function(heightLabels){
                
                //Check unit of measurement
                if (heightLabels.markerPositions && heightLabels.markerPositions[1].label.indexOf("m")>0){
                    setUnitOfMeasurement("meters");
                }

                setHeightLabels(heightLabels);

                //Open the image on the right panel if it was open before
                if (selectedImage != null && !props.zoom){
                    onImageClick(props.image);
                }
              });
        }
      }, [props.image, props.canEdit]);

    function onBboxClick(b){
        setPopupTitle(b.id)
        setPopupContent(<div><div><b>Confidence: </b> {b.confidence.toFixed(2)}</div><div><b>Regex Matched: </b>{getRegexAttribute(b.regexMatchedAttributes)}</div></div>)
        setShowPopup(true)
    }

    function getRegexAttribute(r){
        var attr = ""
        for (const property in r) {
            attr += `${property}: ${r[property]} \n`;
          }
        if (attr.length > 0){
            return attr
        } else {
            return "None"
        }
        
    }

    function toggleShowPopup(){
        setShowPopup(false)
    }

    function toggleShowAttributeEdit(){
        setShowAttributeEdit(false)
    }

    function onImageClick(image){
        if (!props.zoom){
            setSelectedImage(image)
            image.type = props.type;
            eventBus.dispatch("openDiagram", { message: image });
            setTimeout(function(){
                eventBus.dispatch("viewImage", { message: image, jobFeature: props.jobFeature, config: props.config });
            },200)
        }
    }

    const highlightFeature = (e) => {
        let uuid =  e.detail.message;
        //setHighlightFeature(uuid);
      }

      const addAttachment = (e) => {
        calculateAttachmentAttribute(ownerAttr, heightAttr, fieldValue, heightLabel)
        setOwnerAttr(null);
        setHeightAttr(null);
        //setFieldValue(null);
      }
    
      const deleteAttachment = (e) => {
        if (heightLabel != null){
            //delete selected attachment
            var labelToRemove = heightLabels.heightPositions.filter(h=>h.id==heightLabel.id);
            if (labelToRemove.length > 0){
                //Delete from attributes
                delete props.jobFeature.properties[labelToRemove[0].attribute]
                if (labelToRemove[0].owner){
                    delete props.jobFeature.properties[labelToRemove[0].owner]
                }

                //Delete from UI
                let index = heightLabels.heightPositions.indexOf(labelToRemove[0]);
                heightLabels.heightPositions.splice(index,1);
            }
        }
      }

      const zoomIn = (e) => {
        if (Viewer.current != null){
            Viewer.current.zoomOnViewerCenter(1.5)
        }
      }

      const zoomOut = (e) => {
        if (Viewer.current != null){
            Viewer.current.zoomOnViewerCenter(0.85)
        }
      }

      eventBus.on("highlightFeature", highlightFeature);
      eventBus.on("addAttachment", addAttachment);
      eventBus.on("deleteAttachment", deleteAttachment);
      eventBus.on("zoomIn", zoomIn);
      eventBus.on("zoomOut", zoomOut);

        function fieldUpdate(h,e){
             //Find current job type in the array to update
             if (h.owner){
                props.jobFeature.properties[h.owner] = e.currentTarget.value

                //Update label
                var texts = h.label.split(" ")
                var owner = texts[0]
                var newLabel = h.label.replace(owner, e.currentTarget.value);
                h.label = newLabel
             } else {
                //Transformer count
                var texts = h.label.split(" ")
                var count = texts[1]
                var height = texts[2]
                var newLabel = h.label.replace(count, e.currentTarget.value + ",");
                h.label = newLabel

                props.jobFeature.properties[h.attribute] = e.currentTarget.value + ", " + height; 
             }
            
             setFieldValue(e.currentTarget.value);
             setPopupTitle(h.label)
        }

        function addFieldUpdate(h,e){
            //Add a new field and a new label
            setFieldValue(e.currentTarget.value);
       }

    function getAttachmentNameWithX(template){
        //need to translate x, look in existing attributes to find the other owner name
        let possibleIndexes = ["a", "b", "c", "d", "e", "f"];
        var possibleNames = []
        possibleIndexes.forEach(function(index){
            let name = template.replace("_x", "_" + index);
            possibleNames.push(name)
        });
       
        //find owner suffix and height suffix
        let allAttributesNames = Object.keys(props.jobFeature.properties);

        let remainingNames = possibleNames.filter(function(name){ 
            return (allAttributesNames.indexOf(name)<0);
        })

        if (remainingNames.length > 0){
            return remainingNames[0];
        }
        else{
            let lastPossibleName = possibleNames.lastItem;
                return lastPossibleName;
        }
    }

    function getAttachmentHeightFromOwner(ownerName,heightTemplate){
        //Get index from ownerName
        let index = ownerName.substr(ownerName.indexOf("_") + 1, 1);
        return heightTemplate.replace("_x", "_" + index);
    }

    function getAttachmentHeightName(name){
        //find height name from owner name
        var heightName;
            
        //first check to see if self has Height function
        let heightAttrs = props.config.attributes.filter(function(a){
            if (a.default != null && (a.default.indexOf("Height")>=0 || a.default.indexOf("Count")>=0)){
                return true
            } else {
                return false
            }
        }).map(function(a){
            return a.name;
        })

        if (heightAttrs.indexOf(name)>=0){
            //self has Height function
            heightName = name
        } else {
            let possibleHeightNames = props.config.attributes.filter(function(a){ return a.name != name && a.name.indexOf(name)>=0});
            if (possibleHeightNames.count > 0){
                heightName = possibleHeightNames[0]
            }
        }
        
                    
        return heightName
     }

    function calculateAttachmentAttribute(ownerTemplate, heightTemplate, value, h){
        var heightAttrName
        var displayVal = value
        var heightPosition = {}
                  /* attribute:
            'attachment_b_height'
            height:
            1.1309441
            id:
            'D1812215-AFFE-47EE-8754-A03C53A6CE20'
            label:
            'cable_attachment2 3.7ft'
            owner:
            'attachment_b_owner'
            screenHeight:
            390.84455472502134 */
        heightPosition.height = h.height;
        heightPosition.screenHeight=h.screenHeight
        heightPosition.id = uuidv4();
        let heightFormatted = getFormattedHeight(h.height)

            //New attribute
            if (ownerTemplate){
                    //Owner:
                    let ownerName = getAttachmentNameWithX(ownerTemplate)
                    let heightName = getAttachmentHeightFromOwner(ownerName, heightTemplate)
                    //Add new attribute
                    props.jobFeature.properties[ownerName] = value
                    props.jobFeature.properties[heightName] = heightFormatted

                    heightPosition.attribute = heightName
                    heightPosition.owner = ownerName
                    
            } else {
                heightAttrName = getAttachmentHeightName(heightTemplate)
                
                if (heightAttrName != heightTemplate) {
                    props.jobFeature.properties[heightTemplate] = value
                    props.jobFeature.properties[heightAttrName] = heightFormatted

                    heightPosition.attribute = heightAttrName
                    heightPosition.owner = heightTemplate

                    //Remove existing label
                    deleteExistingLabel(heightAttrName);
                } else {
                    //Height only
                    if (heightTemplate.indexOf("_x")>0){
                        heightAttrName = getAttachmentNameWithX(heightTemplate)
                        //Replace the x with index
                        let possibleIndexes = ["a", "b", "c", "d", "e", "f"];
                        let lastDigit = heightAttrName.substr(heightAttrName.length-1,1);
                        let index = possibleIndexes.indexOf(lastDigit.toLowerCase());
                        displayVal = props.config.attributes.filter(function(a){ return a.name == heightTemplate})[0].name;
                        displayVal = displayVal.replace("_x", (index+1).toString());
                        setShowAttributeEdit(false);

                    } else {
                        let field = props.config.attributes.filter(function(a){ return a.name == heightTemplate})[0];
                        if (field && field.default.indexOf("Count")>=0){
                            displayVal = field.displayName + " " + value + ",";
                            props.jobFeature.properties[heightAttrName] = value + ", " + heightFormatted;
                           
                        } else {
                            displayVal = field.displayName;
                            props.jobFeature.properties[heightAttrName] = heightFormatted
                            setShowAttributeEdit(false);
                        }
                        heightAttrName = heightTemplate;
                        deleteExistingLabel(heightAttrName);
                    }
                    
                    heightPosition.attribute = heightAttrName
               }
            }
           
            //Add the height label
            //Delete from UI
 
        heightPosition.label = displayVal + " " + heightFormatted;
        heightLabels.heightPositions.push(heightPosition);
        setHeightLabel(heightPosition);
        setPopupTitle(heightPosition.label)
    }

    function deleteExistingLabel(heightAttrName){
        //Remove existing label
        let existingLabel = heightLabels.heightPositions.filter(function(h){
            return h.attribute == heightAttrName;
        });
        if (existingLabel.length>0){
            let index = heightLabels.heightPositions.indexOf(existingLabel[0]);
            heightLabels.heightPositions.splice(index,1);
        }
    }
        function featureUpdate(h,e){
            //Lookup Owner field from height field
            let heightAttr = e.currentTarget.value;

            let index = heightAttr.indexOf("_x_");
            if (index > 0){
                let heightPrefix = heightAttr.substr(0, index);
                let allAttributesNames = props.config.attributes.map(function(a){
                    //Get the class name in the ()
                    return a.name;
                });

                //Need to make sure it is not a height field with the same prefix
                let heightAttrs = props.config.attributes.filter(function(a){
                    if (a.default != null && a.default.indexOf("Height")>=0){
                        return true
                    } else {
                        return false
                    }
                }).map(function(a){
                    //Get the class name in the ()
                    return a.name;
                });

                let possibleOwnerNames = allAttributesNames.filter(function(name){ 
                    return (name != heightAttr && name.indexOf(heightPrefix)>=0 && heightAttrs.indexOf(name)<0);
                })
                if (possibleOwnerNames.length > 0){
                    let ownerName = possibleOwnerNames[0];
                    setOwnerAttr(ownerName);

                } else {
                    let ownerName = possibleOwnerNames.lastItem
                    setOwnerAttr(ownerName);
                }
            } else {
                setOwnerAttr(null);
            }
            setHeightAttr(heightAttr);
       }

        function getPicklist(field){
            if (field.pickList){
                var picklist = field.pickList.split('|');

            return (picklist.map((p,i) => (
              <option value={p}>{p}</option>
              )));
           }
        }

        function getFeatures(){
            let attributes = props.config.attributes 
            let picklistAttrs = attributes.filter(function(a){
                if (a.default != null && (a.default.indexOf("Height")>=0 || a.default.indexOf("Count")>=0)){
                    return true
                } else {
                    return false
                }
            }).map(function(a){
                //Get the class name in the ()

                if (a.default != null){
                    var feature = {}
                    feature.name = a.name;

                    let startIndex = a.default.indexOf("(");
                    let endIndex = a.default.indexOf(")");
                    feature.className = a.default.substr(startIndex + 1, endIndex - startIndex - 1);
                    
                    return feature
                }
            })
            return picklistAttrs
            
        }

        function getFeaturePicklist(features){
            return (features.map((f,i) => (
              <option value={f.name}>{f.className}</option>
              )));
           
        }
    

        function onMouseMove(e) {
            //adjust offset after zoom
            if (selectedElement && props.canEdit) {
                //evt.preventDefault();
                //var coord = getMousePosition(evt, selectedElement);
                var se = selectedElement.children[0]
                for (var i=0; i<se.children.length; i++){
                    var c = se.children[i];
                    //c.setAttributeNS(null, "x", coord.x - offset.x);
                    if (c.nodeName == "text"){
                        if (c.innerHTML == " + "){
                            c.setAttributeNS(null, "y", e.y+6);//coord.y - offset.y);
                        } else {
                            c.setAttributeNS(null, "y", e.y);//coord.y - offset.y);
                        }
                    } else if (c.nodeName == "line"){
                        c.setAttributeNS(null, "y1", e.y - 5);//coord.y - offset.y - 5);
                        c.setAttributeNS(null, "y2", e.y - 5);//coord.y - offset.y - 5);
                    } else if (c.nodeName == "circle"){
                        c.setAttributeNS(null, "cy", e.y - 4);//coord.y - offset.y);
                    }
                }
                heightLabel.screenHeight = e.y - 5;//coord.y - offset.y - 5
                //convert screen height to height
                heightLabel.height = convertScreenOffsetToHeight(heightLabel.screenHeight)
                updateHeightLabel(heightLabel)
            }
        }

        function startDrag(h,evt) {
            setTool(TOOL_NONE);
            if (evt.currentTarget.classList.contains('draggable') && props.canEdit && isDragging == false && evt.target.nodeName == "text") {
                setSelectedElement(evt.currentTarget);
                setHeightLabel(h);
                setIsDragging(true);
                
                if (h && (h.label == "<-" || h.owner != null || h.label.indexOf(",")>0)){
                    if (h.owner != null){
                        setFieldValue(props.jobFeature.properties[h.owner]);
                    } else {
                        //Check to see if there is count
                        let value = props.jobFeature.properties[h.attribute];
                        if (value && value.indexOf(",")>=0){
                            let count = value.substr(0, 1);
                            setFieldValue(count);
                        } else {
                            setFieldValue(null);
                        }
                        
                    }
                    setOwnerAttr(null);
                    setHeightAttr(null);
                    setShowAttributeEdit(true)
                    
                    if (h.label != "<-"){
                        setPopupTitle(h.label)
                    } else {
                        setPopupTitle("New Attachment")
                    }
                } else {
                    setShowAttributeEdit(false)
                }
                
            }
        }

        function endDrag(h,evt) {
            setSelectedElement(null);
            setIsDragging(false);
            setTool(TOOL_AUTO);
        }

        function getFormattedHeight(height){
            if (unitOfMeasurement == "feet"){
                return (Math.round(height * 3.28084 * 10)/10).toFixed(1) + "ft"
            } else {
                return height.toFixed(1) + "m"
            }
        }

        function updateHeightLabel(h){
            if (h.label == "<-"){
                //
            } else {
                var texts = h.label.split(" ");
                var existingHeight = texts[texts.length-1];
                var newHeight = getFormattedHeight(h.height);
                var newLabel = h.label.replace(existingHeight, newHeight);
                h.label = newLabel
            }
            
            //update attribute
            if (props.jobFeature.properties[h.attribute]){
                props.jobFeature.properties[h.attribute] = props.jobFeature.properties[h.attribute].replace(existingHeight, newHeight)
            }
        }

        function convertScreenOffsetToHeight(screenOffset) {
            //Convert offset to height
            //Check which 5 feet section high is in
            //Screen coordinates starts from top
            let markerPositions = heightLabels.markerPositions
            for (var i=0; i<markerPositions.length-2; i++){
                if (screenOffset <= markerPositions[i].screenHeight && screenOffset > markerPositions[i+1].screenHeight){
                    let topHeight = markerPositions[i+1].screenHeight
                    let baseHeight = markerPositions[i].screenHeight
                    let fiveFeet = 3.048/2
                    let unitHeightInMeters = Math.abs((topHeight-baseHeight)/fiveFeet)
                    return (markerPositions[i].height) + Math.abs(screenOffset - baseHeight) / unitHeightInMeters
                }
            }
            return 0
        }

        function convertHeightToScreenOffset(height, markerPositions) {
            //Convert offset to height
            //Check which 5 feet section high is in
            //Screen coordinates starts from top
            for (var i=0; i<markerPositions.length-2; i++){
                if (height >= markerPositions[i].height && height < markerPositions[i+1].height) {
                    let topHeight = markerPositions[i+1].height
                    let baseHeight = markerPositions[i].height
                    let unitScreenHeight = (baseHeight-topHeight)/(markerPositions[i].screenHeight-markerPositions[i+1].screenHeight)
                    return markerPositions[i+1].screenHeight + (height - topHeight)/unitScreenHeight
                }
            }
            
            return 0
        }

    function getColor(h){
        if (heightLabel && h.id == heightLabel.id){
            return "yellow"
        } else {
            return "white"
        }
    }

    function getHeightPositions(){
        if (props.canEdit == true){
            return heightLabels.heightPositions;
        } else {
            return heightLabels.heightPositions.filter(h=>h.label!="<-")
        }
    }

    function updateHeightFromAttribute(h, markerPositions){

        var heightText = props.jobFeature.properties[h.attribute]
        if (heightText){
            if (heightText.indexOf(",")>0){
                var texts = heightText.split(" ")
                heightText= texts[texts.length-1]
            }

            if (unitOfMeasurement == "feet"){
                var heightInFt = parseFloat(heightText.replace("ft",""))
                if (heightInFt != NaN){
                    h.height = heightInFt * 0.3048

                    var texts = h.label.split(" ")
                    var existingHeight = texts[texts.length-1]
                    
                    var newHeight = (Math.round(h.height * 3.28084 * 10)/10).toFixed(1) + "ft"
                    h.label = h.label.replace(existingHeight, newHeight);
                }
            } else {
                var heightInM = parseFloat(heightText.replace("m",""))
                if (heightInM != NaN){
                    h.height = heightInM

                    var texts = h.label.split(" ")
                    var existingHeight = texts[texts.length-1]
                    
                    var newHeight = h.height.toFixed(1) + "m"
                    h.label = h.label.replace(existingHeight, newHeight);
                }
            }

            if (h.owner){
                //Update label
                var owner = texts[0]
                h.label = h.label.replace(owner, props.jobFeature.properties[h.owner]);
            }

            h.screenHeight = convertHeightToScreenOffset(h.height, markerPositions)
        }
    }

    function updateHeightAttributes(){
        //Get all height attributes:
        let heightAttributes = []

        let attributes = props.config.attributes 
        let heightAttrs = attributes.filter(function(a){
            if (a.default != null && (a.default.indexOf("Height")>=0 || a.default.indexOf("Count")>=0)){
                return true
            } else {
                return false
            }
        }).map(function(a){
            return a.name;
        })

        let allAttributesNames = Object.keys(props.jobFeature.properties);
        allAttributesNames.forEach(function(a){
            if (heightAttrs.indexOf(a)>=0){
                heightAttributes.push(a);
            }
        });

        //Update height positions
        heightLabels.heightPositions.forEach(function(h){
            updateHeightFromAttribute(h, heightLabels.markerPositions)

            //Remove height attribute that has been processed, so that we know which are the new ones to add
            if (h.attribute && h.attribute.length > 0){
                const index = heightAttributes.indexOf(h.attribute);
                heightAttributes.splice(index, 1);
            }
        });

        //Now add the new height positions
        addNewHeightPositions(heightAttributes)
    }

    function addNewHeightPositions(heightAttributes){
        //These are height positions added from the web and therefore not in the sidecar json
        heightAttributes.forEach(function(a){

            var heightPosition = {}
                  /* attribute:
            'attachment_b_height'
            height:
            1.1309441
            id:
            'D1812215-AFFE-47EE-8754-A03C53A6CE20'
            label:
            'cable_attachment2 3.7ft'
            owner:
            'attachment_b_owner'
            screenHeight:
            390.84455472502134 */
            heightPosition.id = uuidv4();
            heightPosition.attribute = a;

            let heightText = props.jobFeature.properties[a];
            //parse height and owner
            if (heightText.indexOf(",")>0){
                var texts = heightText.split(" ")
                heightText= texts[texts.length-1]
            }
            if (unitOfMeasurement == "feet"){
                var heightInFt = parseFloat(heightText.replace("ft",""))
                if (heightInFt != NaN){
                    heightPosition.height = heightInFt * 0.3048;
                }
            } else {
                var heightInM = parseFloat(heightText.replace("m",""))
                if (heightInM != NaN){
                    heightPosition.height = heightInM;
                }
            }
            heightPosition.screenHeight = convertHeightToScreenOffset(heightPosition.height, heightLabels.markerPositions)
                    
            //Lookup config
            let heightAttrs = props.config.attributes.filter(function(c){
                if (c.name == a){
                    //direct match
                    return true
                } else {
                    //has x to replace
                    if (c.name.indexOf("_x")>0){
                        let prefix = c.name.substr(0,c.name.indexOf("_x"))
                        if (a.indexOf(prefix)>=0){
                            return true;
                        }
                    }
                }
            });

            var displayVal;
            if (heightAttrs.length == 1 && heightAttrs[0].name == a){
                //direct match
                displayVal = (heightAttrs[0].displayName != null ? heightAttrs[0].displayName : heightAttrs[0].name) ;
            
            } else if(heightAttrs.length == 1){
                //match with x, no owner
                let possibleIndexes = ["a", "b", "c", "d", "e", "f"];
                let lastDigit = a.substr(a.length-1,1);
                let index = possibleIndexes.indexOf(lastDigit.toLowerCase());
                displayVal = heightAttrs[0].name.replace("_x", (index+1).toString());
                
            } else if(heightAttrs.length == 2){
                //match with owner
                //Find owner value
                let prefix = a.substr(0,heightAttrs[0].name.indexOf("_x")+2);
                let allAttributesNames = Object.keys(props.jobFeature.properties);
                let ownerName = allAttributesNames.filter(function(attr){
                    if (attr.indexOf(prefix)>=0 && attr != a){
                        return true;
                    } else {
                        return false;
                    }
                })
                heightPosition.owner = ownerName[0];
                displayVal = props.jobFeature.properties[ownerName];
            } 
            heightPosition.label = displayVal + " " + getFormattedHeight(heightPosition.height);
            heightLabels.heightPositions.push(heightPosition);
            
        });
    }

    function getLabelOverlay(){

        updateHeightAttributes();

        var positions = getHeightPositions();

        return (
            <svg width={heightLabels.viewPortSize[0]} height={heightLabels.viewPortSize[1]} xmlns="<http://www.w3.org/2000/svg>" ref={svgRef}>

                <image href={props.image.presigned_url} width={heightLabels.viewPortSize[0]} height={heightLabels.viewPortSize[1]}/>

                    {positions.map((h) => (
                        <g className="draggable" onMouseDown={startDrag.bind(this,h)} onMouseUp={endDrag.bind(this,h)}>
                            //horizontal line
                            
                            {h.label != "<-" &&
                                <g>
                                    <line x1={0} y1={h.screenHeight} x2={heightLabels.viewPortSize[0]} y2={h.screenHeight} stroke={getColor(h)} strokeWidth="1"/>

                                    //label background
                                    <text y={h.screenHeight+5} x={heightLabels.viewPortSize[0]} stroke="grey" stroke-opacity="0.4" fill="grey" fillOpacity="0.3"
                                    stroke-width="0.8em" text-anchor="end">{"-".repeat(h.label.length-2)}</text>

                                    //label
                                    <text y={h.screenHeight+5} x={heightLabels.viewPortSize[0]} fill={getColor(h)} fontSize="0.8em" text-anchor="end">{h.label}</text>
                                </g>
                            }
                         </g>
                    ))}

                    {positions.map((h) => (
                        <g className="draggable" onMouseDown={startDrag.bind(this,h)} onMouseUp={endDrag.bind(this,h)}>
                            
                            {props.canEdit == true && h.label == "<-" &&
                                <g>
                                    <line x1={0} y1={h.screenHeight} x2={heightLabels.viewPortSize[0]} y2={h.screenHeight} stroke={getColor(h)} strokeWidth="2"/>

                                    <circle cy={h.screenHeight+1} cx={heightLabels.viewPortSize[0]-16} r="15" fill="lightgrey"/>

                                    //label
                                    <text y={h.screenHeight+12} x={heightLabels.viewPortSize[0]- 4} fill={getColor(h)} fontSize="2.5em" text-anchor="end"> + </text>
                                </g>
                            }
                         </g>
                    ))}

                <line x1={heightLabels.viewPortSize[0]/2} y1={0} x2={heightLabels.viewPortSize[0]/2} y2={heightLabels.viewPortSize[1]} 
                    stroke="white" strokeWidth="2" pointerEvents="all" cursor="pointer"/>

                {heightLabels.markerPositions.map((h) => (
                    <g>
                    //marker text
                    <text y={h.screenHeight+5} x={heightLabels.viewPortSize[0]/2-50} fill="white" fontSize="1em" text-anchor="end">{h.label}</text>

                    //marker line
                    <line x1={heightLabels.viewPortSize[0]/2-10} y1={h.screenHeight} x2={heightLabels.viewPortSize[0]/2} y2={h.screenHeight} 
                    stroke="white" strokeWidth="1" pointerEvents="all" cursor="pointer"/>
                    </g>
                ))}
            </svg>
        )
      }

      function getSvg(){
        return (
            <svg
            width={heightLabels.viewPortSize[0]} height={heightLabels.viewPortSize[1]}
            xmlns="<http://www.w3.org/2000/svg>">

                <image href={props.image.presigned_url} width={heightLabels.viewPortSize[0]} height={heightLabels.viewPortSize[1]}/>

                {heightLabels.boundingBoxes.map((b) => (
                    b.id == "electrical_equipment"
                    ?<rect width={(b.bbox[1][0]*heightLabels.viewPortSize[0])} height={(b.bbox[1][1]*heightLabels.viewPortSize[1])} x={b.bbox[0][0]*heightLabels.viewPortSize[0]} 
                            y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} rx="0" ry="0" fill="green" fillOpacity="0.1" stroke="green" stroke-width="1.5" onClick={onBboxClick.bind(this,b)}/>

                    :b.confidenceFiltered && b.confidenceFiltered == true
                        ?b.regexMatchedAttributes
                            ?<rect width={(b.bbox[1][0]*heightLabels.viewPortSize[0])} height={(b.bbox[1][1]*heightLabels.viewPortSize[1])} x={b.bbox[0][0]*heightLabels.viewPortSize[0]} 
                                y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} rx="0" ry="0" fill="yellow" fillOpacity="0.1" stroke="limegreen" stroke-width="1.5" onClick={onBboxClick.bind(this,b)}/>
                            :<rect width={(b.bbox[1][0]*heightLabels.viewPortSize[0])} height={(b.bbox[1][1]*heightLabels.viewPortSize[1])} x={b.bbox[0][0]*heightLabels.viewPortSize[0]} 
                                y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} rx="0" ry="0" fill="yellow" fillOpacity="0.1" stroke="yellow" stroke-width="1.5" onClick={onBboxClick.bind(this,b)}/>
                        :b.regexMatchedAttributes
                            ?<rect width={(b.bbox[1][0]*heightLabels.viewPortSize[0])} height={(b.bbox[1][1]*heightLabels.viewPortSize[1])} x={b.bbox[0][0]*heightLabels.viewPortSize[0]} 
                                y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} rx="0" ry="0" fill="yellow" fillOpacity="0.1" stroke="limegreen" stroke-width="1.5" strokeDasharray="4 2" onClick={onBboxClick.bind(this,b)}/>
                            :<rect width={(b.bbox[1][0]*heightLabels.viewPortSize[0])} height={(b.bbox[1][1]*heightLabels.viewPortSize[1])} x={b.bbox[0][0]*heightLabels.viewPortSize[0]} 
                                y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} rx="0" ry="0" fill="yellow" fillOpacity="0.1" stroke="yellow" stroke-width="1.5" strokeDasharray="4 2" onClick={onBboxClick.bind(this,b)}/>
                    
                ))}

                {/*heightLabels.boundingBoxes.map((b) => (
                        <text y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])+5} x={b.bbox[0][0]*heightLabels.viewPortSize[0]-3} stroke="grey" fill="grey" fillOpacity="0.3" stroke-opacity="0.3" stroke-width="1em" fontSize="0.6em" text-anchor="end">*</text>
                ))*/}
                {/*heightLabels.boundingBoxes.map((b) => (
                    b.regexMatchedAttributes
                    ?<text y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])+5} x={b.bbox[0][0]*heightLabels.viewPortSize[0]-3}  fill="lightgreen" fontSize="1em" text-anchor="end">*</text>
                    :<text y={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])+5} x={b.bbox[0][0]*heightLabels.viewPortSize[0]-3}  fill="white" fontSize="1em" text-anchor="end">*</text>
                ))*/}

                {/*heightLabels.boundingBoxes.map((b) => (
                    <line x1={0} y1={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} x2={heightLabels.viewPortSize[0]} y2={heightLabels.viewPortSize[1]-((b.bbox[0][1]+b.bbox[1][1])*heightLabels.viewPortSize[1])} 
                    stroke="white" strokeWidth="1" pointerEvents="all" cursor="pointer"/>
                ))*/}

                {/*<text y={heightLabels.viewPortSize[1] + 20} x={50} fill="black" fontSize="1em" text-anchor="end">{heightLabels.comments}</text>*/}
            </svg>
        )
      }

      function getBboxOverlay(showComments){
        if (heightLabels.viewPortSize[0] > 500) {
            var factor = 800/heightLabels.viewPortSize[0]
            if (props.thumbnail == true){
                factor = 400/heightLabels.viewPortSize[0]
            } 
            heightLabels.viewPortSize[0] = heightLabels.viewPortSize[0] * factor
            heightLabels.viewPortSize[1] = heightLabels.viewPortSize[1] * factor
        }
        if (showComments){
            return (<div>
                <div><span>{getSvg()}</span>
                {heightLabels.reportAnIssue &&
                    <span class="report-issue"><b>!</b></span>
                }
                </div>
                <div>{heightLabels.comments}</div>
            </div>)
        } else {
            return getSvg()
        }
        
        
      }

      function getFeatureDropdown(h, features){
        

        return (
            <Form.Group as={Row} className="mb-2">
            <Form.Label column sm={4}>Feature</Form.Label>
            <Col sm={8}><Form.Select name="feature" value={heightAttr} onChange={featureUpdate.bind(this,h)}>
                {<option value=""></option>}
                {getFeaturePicklist(features)}
                </Form.Select></Col>
            </Form.Group>
        )
      }
      function getAttributeEdit(){

        let h = heightLabel;
        if (h && h.label == "<-"){
            //add new attachment
            let features = getFeatures();

            //Get picklist for model features
            if (features){
              if (ownerAttr){
                //Get picklist
                var field = props.config.attributes.filter(function(f){
                    return f.name == ownerAttr
                })[0];
                return(
                    <Form>
                    {getFeatureDropdown(h, features)}
                    <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={4}>Owner</Form.Label>
                    <Col sm={8}><Form.Select name={h.owner} value={fieldValue} onChange={addFieldUpdate.bind(this,h)}>
                        {<option value=""></option>}
                        {getPicklist(field)}
                        </Form.Select></Col>
                    </Form.Group>
                    <center><Button variant="primary" type="button" onClick={addAttachment.bind(this)}>Add</Button></center>
                    </Form>
                    )
              } else {
                //Check to see if it is a count field
                var field = props.config.attributes.filter(function(f){
                    return f.name == heightAttr
                })[0];
                if (field && field.default && field.default.indexOf("Count")>=0){
                    return (
                        <Form>
                            {getFeatureDropdown(h, features)}
                            <Form.Group as={Row} className="mb-2">
                            <Form.Label column sm={4}>Count</Form.Label>
                            <Col sm={8}><Form.Select name="count" value={fieldValue} onChange={addFieldUpdate.bind(this,h)}>
                                <option value=""></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                </Form.Select></Col>
                            </Form.Group>
                            <center><Button variant="primary" type="button" onClick={addAttachment.bind(this)}>Add</Button></center>
                        </Form>
                    )
                } else {
                    return(
                        <Form>
                            {getFeatureDropdown(h, features)}
                            <center><Button variant="primary" type="button" onClick={addAttachment.bind(this)}>Add</Button></center>
                        </Form>
                    )
                }
              }
            }
        } else if (h) {
            if (h.owner){
                var ownerField;
                var firstIndex = h.owner.indexOf("_")
                var secondIndex = h.owner.indexOf("_", firstIndex)
                if (firstIndex > 0 && secondIndex >0){
                    var placeholder = h.owner.substr(firstIndex,3)
                    ownerField = h.owner.replace(placeholder, "_x_");
                }
            } 
            
            //Get picklist
            var field = props.config.attributes.filter(function(f){
                return f.name == ownerField
              })[0];

            
            if (field){
                console.log("fieldValue: " + fieldValue)
                return(
                    <Form>
                    <Form.Group as={Row} className="mb-2">
                    <Form.Label column sm={4}>Owner</Form.Label>
                    <Col sm={8}><Form.Select name={h.owner} value={fieldValue} onChange={fieldUpdate.bind(this,h)}>
                        {<option value=""></option>}
                        {getPicklist(field)}
                        </Form.Select></Col>
                    </Form.Group>
                    </Form>
                    )
            } else {
                //Check to see if there is a Count field
                var field = props.config.attributes.filter(function(f){
                    return f.name == h.attribute
                  })[0];
                
                if (field && field.default && field.default.indexOf("Count")>=0){
                    return(
                        <Form>
                        <Form.Group as={Row} className="mb-2">
                        <Form.Label column sm={4}>Count</Form.Label>
                        <Col sm={8}><Form.Select name="count" value={fieldValue} onChange={fieldUpdate.bind(this,h)}>
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            </Form.Select></Col>
                        </Form.Group>
                        </Form>
                        )
                }
            }
        }
    }

      function addHeightLabels(heightLabels, showComments){
        if (props.type == "label"){
            return getLabelOverlay();
        } else {
            return getBboxOverlay(showComments);
        }
      }

      // assign elementRef to the ref of our component
      if (heightLabels && heightLabels.viewPortSize){
        if (props.zoom == true){
            return (
                <div className="imageContainer" onClick={onImageClick.bind(this,props.image)}>
                    <ReactSVGPanZoom
                            tool={tool} onChangeTool={setTool}
                            value={value} onChangeValue={setValue}
                            ref={Viewer}
                            width={props.height + 50} height={props.height}
                            detectAutoPan={false}
                            detectPinch={true}
                            detectWheel={true}
                            scaleFactor={1.5}
                            toolbarProps={toolbar}
                            onMouseMove={onMouseMove}
                        >
                    {
                        addHeightLabels(heightLabels, false)
                    }
                    </ReactSVGPanZoom>
                    <ToastContainer className="position-static">
                        <Toast className="popupContainer" show={showPopup} onClose={toggleShowPopup}>
                            <Toast.Header>
                                <strong className="me-auto">{popupTitle}</strong>
                            </Toast.Header>
                            <Toast.Body>{popupContent}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <ToastContainer className="position-static">
                        <Toast className="popupContainer" show={showAttributeEdit} onClose={toggleShowAttributeEdit}>
                            <Toast.Header>
                                <strong className="me-auto">{popupTitle}</strong>
                            </Toast.Header>
                            <Toast.Body>{getAttributeEdit()}</Toast.Body>
                        </Toast>
                    </ToastContainer>
                </div>
            );
          } else {
            return (
                <div className="imageContainer" onClick={onImageClick.bind(this,props.image)}>
                {
                    addHeightLabels(heightLabels, true)
                }
                </div>
            );
          }
        } 
}