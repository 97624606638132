import axios from 'axios';
import { getAppConfig } from "../config";

export const fields =  [
  {
    name: "ObjectID",
    alias: "ObjectID",
    type: "oid"
  }, {
    name: "name",
    alias: "Name",
    type: "string"
  }, {
    name: "org_id",
    alias: "Org ID",
    type: "string"
  }, {
    name: "config_org_id",
    alias: "Config Org ID",
    type: "string"
  }, {
    name: "org_name",
    alias: "Org Name",
    type: "string"
  }, {
    name: "config_org_name",
    alias: "Config Org Name",
    type: "string"
  }, {
    name: "build_number",
    alias: "Build Number",
    type: "string"
  }, {
    name: "comments",
    alias: "Comments",
    type: "string"
  }, {
    name: "date",
    alias: "Date",
    type: "string"
  }, {
    name: "uuid",
    alias: "Job Id",
    type: "string"
  }, {
    name: "job_type",
    alias: "Job Type",
    type: "string"
  }, {
    name: "config_id",
    alias: "Config Id",
    type: "string"
  }, {
    name: "model_id",
    alias: "Model Id",
    type: "string"
  }, {
    name: "model",
    alias: "Model Name",
    type: "string"
  }, {
    name: "device_model",
    alias: "Device Model",
    type: "string"
  }, {
    name: "device_model_name",
    alias: "Device Model Name",
    type: "string"
  }, {
    name: "device_id",
    alias: "Device ID",
    type: "string"
  }, {
    name: "device_name",
    alias: "Device Name",
    type: "string"
  }, {
    name: "feature_count",
    alias: "Feature Count",
    type: "string"
  }, {
    name: "recognition_duration_average",
    alias: "RecognitionDurationAverage",
    type: "string"
  }, {
    name: "recognition_duration_maximum",
    alias: "RecognitionDurationMaximum",
    type: "string"
  }, {
    name: "recognition_duration_minimum",
    alias: "RecognitionDurationMinimum",
    type: "string"
  }, {
    name: "system_name",
    alias: "System Name",
    type: "string"
  }, {
    name: "system_version",
    alias: "System Version",
    type: "string"
  }, {
    name: "user_name",
    alias: "User",
    type: "string"
  }, {
    name: "user_initials",
    alias: "User Initials",
    type: "string"
  }, {
    name: "job_name",
    alias: "Job Name",
    type: "string"
  }
];

//Format jobs for map display, create attributes for map display.
function formatJobs(jobs){

  var formattedJobs = jobs.items.map(function(j){
    var job = j;
    job.attributes = [];
    fields.forEach(function(f){
      job.attributes[f.name] = j[f.name];
    });
    return job;
  });
  
  jobs.items = formattedJobs
  return jobs;
}

//Get organizations
export const fetchOrganizations = async (accessToken) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/organizations/all';
    
    try{
      var orgs = await axios.get(orgurl, config);
      return orgs.data;
    }
    catch(ex){
      return [];
    }
}

//Get jobs for an organization
export const getOrganizationJobs = async (accessToken, orgId, pagingKey) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };

    ///job/organization?limit=50&lastEvaluatedKey=eyJvcmdfaWQiOiAiMTIzIn0=
    
    var joburl = appconfig.api + '/job/organization';
    if (orgId){
      joburl += "/" + orgId
    }

    var countUrl = joburl + "/count";

    joburl += "?limit=50"

    if (pagingKey){
      joburl += "&lastEvaluatedKey=" + encodeURI(pagingKey);
    }

    try{
     var jobs = await axios.get(joburl, config);
     if (!pagingKey){
        var count = await axios.get(countUrl, config);
        var jobs = formatJobs(jobs.data);
        jobs.count = count.data.count;
        return jobs;
      } else {
        return formatJobs(jobs.data);
      }

    }
    catch(ex){
      return [];
    }
}

//Get jobs for login user
export const getMyJobs = async (accessToken, orgId, pagingKey) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var joburl = appconfig.api + '/job';
    if (orgId){
      joburl += "s/" + orgId
    }

    var countUrl = joburl + "/count";

    joburl += "?limit=50"

    if (pagingKey){
      joburl += "&lastEvaluatedKey=" + encodeURI(pagingKey);
    }

    try{
      var jobs = await axios.get(joburl, config);

      //Get count if not paging
      if (!pagingKey){
        var count = await axios.get(countUrl, config);
        var jobs = formatJobs(jobs.data);
        jobs.count = count.data.count;
        return jobs;
      } else {
        return formatJobs(jobs.data);
      }
      
    }
    catch(ex){
      return [];
    }
}

//Get single job
export const getJob = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid;

  try{
    var result = await axios.get(joburl, config);
    
    return result.data;
  }
  catch(ex){
    return [];
  }
}

export const updateComment = async (accessToken, jobid, comment) => {
  const appconfig = getAppConfig();
  //First do a post to /job to get the presigned url for upload:
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
 var joburl = appconfig.api + '/job/' + jobid + "/comments";

  try{
    var response = await axios.put(joburl, comment, config);
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const approveJob = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  //First do a post to /job to get the presigned url for upload:
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

 var joburl = appconfig.api + '/job/' + jobid + "/approve";

  try{
    var response = await axios.put(joburl, null, config);
    return response;
  }
  catch(ex){
    return ex;
  }
}

//Get job features
export const getJobFeatures = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/features';

  try{
    var jobFeatures = await axios.get(joburl, config);
    
    return jobFeatures.data;
  }
  catch(ex){
    return [];
  }
}

// /job/:uuid/features
export const updateJobFeatures = async (accessToken, jobid, features) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var featuresToUpdate = [...features];
  featuresToUpdate.forEach(function(f){
    f.images.forEach(function(image){
      if (image.jobFeature){
        delete image.jobFeature
      }
    })
  })

  var joburl = appconfig.api + '/job/' + jobid + '/features';
  var data = {features:featuresToUpdate, type:'FeatureCollection'}
  try{
    var response = await axios.put(joburl, data, config);
    
    return response;
  }
  catch(ex){
    return ex;
  }
}

//Get job images
export const getJobImages = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/images';
  
  try{
    var jobImages = await axios.get(joburl, config);
    
    return jobImages.data;
  }
  catch(ex){
    return [];
  }
}

//Get job images
export const getJobObservations = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/observations';
  
  try{
    var jobImages = await axios.get(joburl, config);
    
    return jobImages.data;
  }
  catch(ex){
    return [];
  }
}

export const getJobVideos = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/video';
  
  try{
    var jobVideos = await axios.get(joburl, config);
    
    return [jobVideos.data];
  }
  catch(ex){
    return [];
  }
}

//job/:uuid/geofeatures
//job/:uuid/userlocations
//job/:uuid/debug
//job/:uuid/sys

//Get user locations
export const getUserLocations = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/userlocations';

  try{
    var userLocations = await axios.get(joburl, config);
    
    var userLocations = csvFileToArray(userLocations.data);
    return userLocations;
  }
  catch(ex){
    return [];
  }
}

//Download csv
export const downloadJobCsv = async (accessToken, jobids) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/octet-stream' }
  };
  
  var joburl = appconfig.api + '/jobs/features/export/csv';

  try{
    var download = await axios.post(joburl, {
      responseType: 'blob',
      "job_uuids": jobids
    }, config);
    
    return download.data;
  }
  catch(ex){
    return [];
  }
}

//Download csv
export const downloadJobKmz = async (accessToken, jobids) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/octet-stream' }
  };
  
  var joburl = appconfig.api + '/jobs/features/export/kmz';

  try{
    var download = await axios.post(joburl, {
      responseType: 'blob',
      "job_uuids": jobids
    }, config);
    
    return download.data;
  }
  catch(ex){
    return [];
  }
}

//Get user locations
export const getGeoFeatures = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/geofeatures';

  try{
    var geofeatures = await axios.get(joburl, config);
    
    var geofeaturesJson = csvFileToArray(geofeatures.data);
    return geofeaturesJson;
  }
  catch(ex){
    return [];
  }
}

export const getJobCompare = async (accessToken, jobid1, jobid2) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };

  //https://api.dev.sspvision.net/jobs/compare/7F472805-AA3B-4AAD-B231-DEA0FDD8AF09/DD9FADCE-D641-4412-802C-0E701F441FB8
  
  var joburl = appconfig.api + '/jobs/compare/' + jobid1 + '/' + jobid2;

  try{
    var result = await axios.get(joburl, config);
    
    return result;
  }
  catch(ex){
    return null;
  }
}

const csvFileToArray = string => {
  var array = string.toString().split("\n")
 //  console.log(array); here we are getting the first rows which is our header rows to convert it into keys we are logging it here
   var data = []
   // console.log(data);
   for(const r of array){
     // console.log(r);
       let row = r.toString().split(",")
       data.push(row)
   }
   //console.log(data)
   var heading = data[0]
   // console.log(heading); to get the column headers which will act as key
   var ans_array = []
   // console.log(ans_array);
   for(var i=1;i<data.length;i++){
       var row = data[i]
       var obj = {}
       for(var j=0;j<heading.length;j++){
           if(!row[j]){
               row[j]="";
           }
           // console.log(row[j].toString())
           obj[heading[j].replaceAll(" ","_")] = row[j].toString().replaceAll(" ","_")
       }
       ans_array.push(obj)
   }
   //console.log({ans_array})
   return ans_array
};

export const getDebugLog = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/debug';

  try{
    var debug = await axios.get(joburl, config);
    
    return debug.data;
  }
  catch(ex){
    return [];
  }
}

export const getSysLog = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var joburl = appconfig.api + '/job/' + jobid + '/sys';

  try{
    var sys = await axios.get(joburl, config);
    
    return sys.data;
  }
  catch(ex){
    return [];
  }
}

export const deleteJob = async (accessToken, jobid) => {
  const appconfig = getAppConfig();
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
    var joburl = appconfig.api + '/job/' + jobid;

    try{
      var result = await axios.delete(joburl, config);
      
      return result.data;
    }
    catch(ex){
      return ex;
    }
  
  
}

export const downloadJob = async (accessToken, jobid) => {
 
  try{
    var job = await getJob(accessToken, jobid);
    var download = await axios.get(job.download_url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    
    return download.data;
  }
  catch(ex){
    return null;
  }
}

export const uploadJob = async (accessToken, selectedFile) => {
  const appconfig = getAppConfig();
  //First do a post to /job to get the presigned url for upload:
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
 var joburl = appconfig.api + '/job';
 var param = {filename:`${selectedFile.name}`}
  try{
    var result = await axios.post(joburl, param, config);
    
    //Now we have the url to upload the file
    var uploadUrl = result.data.url;

    var options = {
      headers: {
        'Content-Type': selectedFile.type
      }
    };
   
    var response = await axios.put(uploadUrl, selectedFile, options);
   
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

export const getHeightLabels = async (presigned_url) => {

  try{
    //var finalUrl = presigned_url.replaceAll("\u0026","&")
    var heightLabels = await axios.get(presigned_url);
    
    return heightLabels.data;

    /*
    return {
      "heightPositions" : [
        {
          "height" : 0.91528165,
          "id" : "5987062657376471219",
          "screenHeight" : 609.4329192108811,
          "label" : "<-",
          "attribute" : ""
        },
        {
          "height" : 1.2384826,
          "label" : "Light 4.1ft",
          "id" : "387A00CD-69DD-4724-B052-B73993DB25F8",
          "attribute" : "light",
          "screenHeight" : 609.6449933124369
        },
        {
          "attribute" : "attachment_a_height",
          "id" : "251564B4-2010-400A-AB0D-5223DC0B32AB",
          "screenHeight" : 609.6157331429423,
          "label" : "cable_attachment1 3.9ft",
          "height" : 1.1938901
        },
        {
          "attribute" : "attachment_b_height",
          "label" : "cable_attachment2 3.6ft",
          "screenHeight" : 609.561683777939,
          "height" : 1.1115189,
          "id" : "D5EEB741-FE45-4F6D-9604-340921A11EAF"
        },
        {
          "screenHeight" : 298.2013875398181,
          "id" : "C1F3D5B9-ADCD-44D1-8BBF-54AE185AF500",
          "height" : 2.2194595,
          "label" : "Height 7.3ft",
          "attribute" : "height"
        },
        {
          "attribute" : "telephone_box",
          "screenHeight" : 609.4225862141341,
          "id" : "90BB729E-F303-412E-A5CF-D4E112069277",
          "height" : 0.89953417,
          "label" : "Telephone Box 3ft"
        },
        {
          "height" : 2.0994625,
          "screenHeight" : 298.1226493249368,
          "id" : "2BCE8A44-1BC0-4525-B9C3-59E813BA1A9F",
          "label" : "cross_arm_single1 6.9ft",
          "attribute" : "cross_arm_single_a"
        },
        {
          "id" : "2D9297BC-4325-4905-AD0D-032257EA9595",
          "attribute" : "cross_arm_single_b",
          "label" : "cross_arm_single2 6.6ft",
          "screenHeight" : 298.0696762769559,
          "height" : 2.0187316
        },
        {
          "height" : 0.98311675,
          "id" : "DD9249C3-DB32-47D7-96AE-8D18FEB52AF2",
          "label" : "Telephone Can 3.2ft",
          "attribute" : "telephone_can",
          "screenHeight" : 609.4774304250409
        },
        {
          "attribute" : "comm_arm",
          "height" : 1.6144487,
          "label" : "Comm Arm 5.3ft",
          "id" : "10723EFC-7FCA-43E8-B948-9B4114FBA455",
          "screenHeight" : 297.8043987324298
        },
        {
          "height" : 1.4655333,
          "id" : "9DA647A6-23A9-48B5-AB8A-035BDEFB921B",
          "label" : "Low Power Attachment 4.8ft",
          "screenHeight" : 609.7939766817246,
          "attribute" : "low_power_attachment"
        },
        {
          "height" : 1.7765388,
          "id" : "91D8D7ED-E6AB-4C70-9CFD-7E2988B7CEF0",
          "attribute" : "transformer",
          "screenHeight" : 297.91075713301325,
          "label" : "Transformer 2, 5.8ft"
        }
      ],
      "markerPositions" : [
        {
          "label" : "0",
          "screenHeight" : 608.8323407173157,
          "height" : 0
        },
        {
          "label" : "5'",
          "screenHeight" : 297.74504923820496,
          "height" : 1.524
        },
        {
          "screenHeight" : -2.1586945056915283,
          "height" : 3.0479999,
          "label" : "10'"
        },
        {
          "label" : "15'",
          "screenHeight" : -291.4712977409363,
          "height" : 4.572
        },
        {
          "height" : 6.096,
          "label" : "20'",
          "screenHeight" : -570.7441177368164
        },
        {
          "screenHeight" : -840.490731716156,
          "height" : 7.6200004,
          "label" : "25'"
        },
        {
          "label" : "30'",
          "screenHeight" : -1101.190357685089,
          "height" : 9.144
        },
        {
          "screenHeight" : -1353.2907218933105,
          "height" : 10.668,
          "label" : "35'"
        },
        {
          "label" : "40'",
          "screenHeight" : -1597.2103729248047,
          "height" : 12.192
        },
        {
          "height" : 13.716001,
          "label" : "45'",
          "screenHeight" : -1833.3413982391357
        },
        {
          "height" : 15.240001,
          "label" : "50'",
          "screenHeight" : -2062.0504302978516
        },
        {
          "height" : 16.764,
          "label" : "55'",
          "screenHeight" : -2283.6825704574585
        },
        {
          "height" : 18.288,
          "label" : "60'",
          "screenHeight" : -2498.5605840682983
        },
        {
          "height" : 19.812,
          "label" : "65'",
          "screenHeight" : -2706.9891262054443
        },
        {
          "screenHeight" : -2909.254942893982,
          "label" : "70'",
          "height" : 21.336
        },
        {
          "label" : "75'",
          "screenHeight" : -3105.625663757324,
          "height" : 22.86
        },
        {
          "height" : 24.384,
          "label" : "80'",
          "screenHeight" : -3296.3570461273193
        }
      ]
    }
    */
    
  }
  catch(ex){
    return [];
  }

}