import axios from 'axios';
import jwt_decode from "jwt-decode";
import { getAppConfig } from "../config";

export const createM2MClient = async (accessToken, superadminOrgId, orgId) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/client';
  if (superadminOrgId){
    url += "/" + superadminOrgId;
  }
  
  try{
    var response = await axios.post(url, null, header);
    if (response.data){
        //Fetch org metadata
        return getOrg(accessToken, orgId).then(function(org){
          //Set client ID and Secret
        org.metadata.client_id = response.data.client_id;
        
        //Save into Org Metadata
        updateOrg(accessToken, org)

        return response.data
    })
    }
    else{
      return response.data;
    }
  }
  catch(ex){
    return ex;
  }
}

export const rotateM2MClientSecret = async (accessToken, client_id) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/client/' + client_id + '/rotate';
  
  try{
    var response = await axios.put(url, null, header);
    
    return response.data;
    
  }
  catch(ex){
    return ex;
  }
}

export const deleteM2MClient = async (accessToken, client_id) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/client';
  if (client_id){
    url += "/" + client_id;
  }
  
  try{
    var response = await axios.delete(url, header);
    
    return response
   
  }
  catch(ex){
    return ex;
  }
}

//Get organizations
export const getOrg = async (accessToken, orgId) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/organizations/' + orgId;
    
    try{
      var org = await axios.get(orgurl, config);
      return org.data;
    }
    catch(ex){
      return [];
    }
}

export const getM2MCredential = async (accessToken, client_id) => {
  const appconfig = getAppConfig();
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var orgurl = appconfig.api + '/client/' + client_id;
    
    try{
      var org = await axios.get(orgurl, config);
      return org.data;
    }
    catch(ex){
      return [];
    }
}

export const updateOrg = async (accessToken, org) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + org.id;
  var org_copy = {...org};
  delete org_copy.id;

  try{
  
    var response = await axios.put(url, org_copy, header);
    
    return response;
    
  }
  catch(ex){
    return ex;
  }
}

//Get config (job types) for an organization
export const getFTPSettings = async (accessToken, orgId) => {
    const appconfig = getAppConfig();
    const header = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    
    var url = appconfig.api + '/connection';
    if (orgId){
      url += "/" + orgId;
    }
    try{
      var result = await axios.get(url, header);
      if (result.data){
        var ftpData = result.data.filter(data=>{return data.type == "sftp"})
        if (ftpData.length > 0){
          var ftpServer = ftpData[0].credvars;
          ftpServer.path = ftpData[0].path;
          ftpServer.type = ftpData[0].type;
          ftpServer.uuid = ftpData[0].uuid;
          return ftpServer;
        } else {
          return null;
        }
      }
      else{
        return result.data;
      }
      /*
              {
                    "server": "ftp.test.com",
                    "username": "testing",
                    "port": "22",
                    "password": "password",
                    "type": "sftp",
                    "path": "/home"
                }
              {
                    "uuid": "b76bb5c2-f1f5-406c-92dd-76ac68da8225",
                    "org_id": "org_eRVy30HTqqBcKG13",
                    "type": "sftp",
                    "credentials": "arn:aws:secretsmanager:us-west-2:933548525879:secret:ftp/org_eRVy30HTqqBcKG13-22COnh",
                    "credvars": {
                        "server": "ftp.test.com",
                        "username": "testing",
                        "port": "22",
                        "password": "password",
                        "type": "",
                        "url": "sftp://testing:password@ftp.test.com:22"
                    },
                    "rules": "",
                    "path": "/home"
                }
              */

      
    }
    catch(ex){
      return null;
    }
  
}

export const updateConnectionSettings = async (accessToken, settings) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/connection';
  if (settings.uuid){
    url += "/" + settings.uuid;
  }
  
  try{
    
    var settings_copy = {...settings};
    delete settings_copy.url;
    delete settings_copy.uuid;
    var response = await axios.put(url, settings_copy, header);
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const deleteConnectionSettings = async (accessToken, settings) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/connection';
  if (settings.uuid){
    url += "/" + settings.uuid;
  }
  
  try{
    var response = await axios.delete(url, header);
    return response;
  }
  catch(ex){
    return ex;
  }
}

export const createConnectionSettings = async (accessToken, orgId, settings) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/connection';
  if (orgId){
    url += "/" + orgId;
  }
  
  try{
    var response = await axios.post(url, settings, header);
    return response;
  }
  catch(ex){
    return ex;
  }
}

//Get config (job types) for an organization
export const getEsriSettings = async (accessToken, orgId) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/connection';
  if (orgId){
    url += "/" + orgId;
  }
  try{
    var result = await axios.get(url, header);
    if (result.data){
      var esriData = result.data.filter(data=>{return data.type == "esri"})
      if (esriData.length > 0){
        var esriServer = esriData[0].credvars;
        esriServer.type = esriData[0].type;
        esriServer.uuid = esriData[0].uuid;
        return esriServer;
      } else {
        return null
      }
    }
    else{
      return null
    }
  }
  catch(ex){
    return null;
  }

}

export const getModelDataset = async (accessToken, orgId) => {
  const appconfig = getAppConfig();
  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/connection';
  if (orgId){
    url += "/" + orgId;
  }
  try{
    var result = await axios.get(url, header);
    if (result.data){
      var results = result.data.filter(data=>{return data.type == "labelbox"})
      if (results.length > 0){
        var modelDataset = results[0].credvars;
        modelDataset.type = results[0].type;
        modelDataset.uuid = results[0].uuid;
        return modelDataset;
      } else {
        return null
      }
    }
    else{
      return null
    }
  }
  catch(ex){
    return null;
  }

}

export const createModelDataset = async (accessToken, orgId) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + orgId + "/createDataset";
  
  
  try{
    var response = await axios.post(url, {}, header);

    return response;
  }
  catch(ex){
    return ex;
  }
}

export const syncModelDataset = async (accessToken, orgId, modelDataset) => {
  
  const appconfig = getAppConfig();

  const header = {
    headers: { Authorization: `Bearer ${accessToken}` }
  };
  
  var url = appconfig.api + '/organizations/' + orgId + "/invokeDriveSyncLambda";
  
  var settings = {
  "labelbox_dataset": modelDataset.lb_dataset,//"clt4pr5x70042071739n5x3hq",
  "drive_url": modelDataset.drive_folder,//"https://drive.google.com/drive/folders/1pndrwK1CMxaCcGwQtF2Sm82OM4PPmAOj?usp=drive_link",
  "bucket_name": modelDataset.bucket_name//"test-bucket-from-go"
  }

  try{
    var response = await axios.post(url, settings, header);
    
    return response;
  }
  catch(ex){
    return ex;
  }
}